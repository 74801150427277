<template>
  <div class="nrhc_container">
    <div class="list">
      <div
        class="card"
        v-for="item in userBindInfos"
        :key="item.patCardNo"
        @click="detail(item)"
      >
        <img src="../../../assets/pic.png" class="base" />
        <div class="label">广东省(市)卫生健康委员会</div>
        <img
          :src="'data:image/png;base64,' + item.qrCode"
          class="qr"
          v-if="item.qrCode"
        />
        <div class="name">{{ item.name }}</div>
        <div class="pat_card_no">{{ item.patCardNo }}</div>
        <div class="card_no">{{ item.cardNo_2 }}</div>
      </div>
    </div>
    <div class="btn" v-if="userBindInfos !== ''">
      <van-button type="primary" block @click="navHome"
        >返&nbsp;回&nbsp;首&nbsp;页</van-button
      >
      <van-button type="info" block style="margin-top: 10px;" @click="add"
        >添&nbsp;加&nbsp;健&nbsp;康&nbsp;卡</van-button
      >
    </div>
    <van-empty description="暂无内容" v-if="userBindInfos === ''" />
  </div>
</template>
<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Nrhc",
  data() {
    return {
      userBindInfos: []
    };
  },
  created() {
    if (window.localStorage.getItem("openId") === null) {
      this.getCode();
    } else {
      this.getUserBindInfos();
    }
  },
  methods: {
    // 获取code
    getCode() {
      let local = window.location.href;
      let appid = "wxcdae494f7e9422e7";
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
        local
      )}&response_type=code&scope=snsapi_base&state=123&#wechat_redirect`;
      // window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxcdae494f7e9422e7&redirect_uri=http://tssywx.tssrmyy.com&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect"
      let str = window.location.href;
      let code = str.split("code=")[1].split("&")[0];
      this.code = code;
      this.login(code);
    },
    //获取openId
    async login(code) {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true
      });
      let data = qs.stringify({
        code: code
      });
      const { data: res } = await formPost("/wxapp/login", data);
      this.$toast.clear();
      if (res.code === 0) {
        window.localStorage.setItem("openId", res.data.openid);
        this.getUserBindInfos();
      } else {
        this.$toast.fail(res.msg);
      }
    },
    //获取健康卡列表
    async getUserBindInfos() {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      let data = qs.stringify({
        openId: window.localStorage.getItem("openId")
      });
      const { data: res } = await formPost(
        "/wxapp/userBindRecord/userBindRecord/getUserBindInfos",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.userBindInfos = res.data;
      } else {
        this.userBindInfos = "";
        this.$toast.fail("您暂未绑定任何患者就诊档案信息，请先建档");
        setTimeout(() => {
          this.$router.push({
            path: "/file"
          });
        }, 2000);
      }
    },
    detail(item) {
      if (typeof item.qrCode === "string") {
        this.$router.push({
          path: "/me/nrhc/detail",
          query: {
            patCardNo: item.patCardNo,
            erhcCardNo: item.erhcCardNo
          }
        });
      } else {
        let info = JSON.stringify(item);
        this.$toast(
          "“" +
            item.name +
            "”" +
            "档案信息不完整，请完善个人信息绑定电子健康卡或解绑"
        );
        setTimeout(() => {
          this.$router.push({
            path: "/file",
            query: {
              info: info,
              isUpdata: 1
            }
          });
        }, 2000);
      }
    },
    navHome() {
      this.$router.push({
        path: "/"
      });
    },
    add() {
      this.$router.push({
        path: "/file"
      });
    }
  }
};
</script>
<style scope>
.nrhc_container .list {
  padding: 0px 40px;
  margin-bottom: 280px;
}
.nrhc_container .card {
  position: relative;
  margin-top: 40px;
  height: 350px;
  width: 100%;
}
.nrhc_container .card .base {
  height: 100%;
  width: 100%;
}
.nrhc_container .card .label {
  position: absolute;
  top: 36px;
  left: 40px;
  font-size: 24px;
  font-weight: 700;
}
.nrhc_container .card .qr {
  position: absolute;
  top: 100px;
  right: 40px;
  width: 190px;
  height: 190px;
}
.nrhc_container .card .name {
  position: absolute;
  top: 120px;
  left: 40px;
  font-size: 34px;
  font-weight: 700;
}
.nrhc_container .card .pat_card_no {
  position: absolute;
  top: 190px;
  left: 40px;
  font-size: 34px;
  font-weight: 700;
}
.nrhc_container .card .card_no {
  position: absolute;
  top: 250px;
  left: 40px;
  font-size: 34px;
  font-weight: 700;
}
.nrhc_container .btn {
  width: 100%;
  height: 190px;
  position: fixed;
  left: 0;
  bottom: 60px;
  z-index: 1;
  background-color: white;
}
.nrhc_container .btn .van-button {
  font-size: 34px !important;
  border-radius: unset !important;
  border: unset !important;
}
</style>
